var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"60px","margin-top":"20px"},attrs:{"data-anima":"top"}},[_c('div',{staticStyle:{"display":"flex","gap":"70px"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("minha_conta.title")))]),(_vm.$route.path === '/minha-conta')?_c('div',{staticStyle:{"top":"-7px","width":"50%","position":"relative"}},[_c('Locales')],1):_vm._e()]),_c('modal-upload'),_c('div',{class:{ containerx: !_vm.isMobile, '': _vm.isMobile }},[_c('div',{class:{
        'container-perfil': !_vm.isMobile,
        'container-perfil-mobile': _vm.isMobile,
      }},[_c('div',{staticClass:"side-left width100",class:{ width100: _vm.isMobile }},[_c('div',{staticClass:"animationOpacity2 width100",class:{
            'edicao-perfil': !_vm.isMobile,
            'edicao-perfil-mobile': _vm.isMobile,
          }},[_c('div',{class:{
              spaceConfigPainel: !_vm.isMobile,
              spaceConfigPainelMobile: _vm.isMobile,
            }},[(!_vm.isMobile)?_c('div',{staticClass:"coluna"},[_c('div',{staticClass:"flexProfile"},[_c('div',{staticClass:"spacePic"},[(_vm.picProfile)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"foto-usuario",class:{ contains: _vm.picProfile },staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.$t('minha_conta.txt6'),"src":_vm.picProfile},on:{"click":function($event){_vm.show = true}}}):_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"foto-usuario",staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.$t('minha_conta.txt6')},on:{"click":function($event){_vm.show = true}}},[_c('span',[_vm._v(_vm._s(_vm.firstName ? _vm.firstName.substr(0, 1) : ''))])])]),_c('div',[_c('div',[_c('my-upload',{attrs:{"field":"value","langType":"pt-br","width":100,"height":100,"url":_vm.urlPost,"params":_vm.params,"headers":_vm.headers,"img-format":"png"},on:{"crop-success":_vm.cropSuccess,"crop-upload-success":_vm.cropUploadSuccess,"crop-upload-fail":_vm.cropUploadFail},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('img',{attrs:{"src":_vm.imgDataUrl}})],1)]),_c('div',{staticClass:"spaceDados"},[_c('h4',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.firstName))]),_c('p',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.email))]),_c('p',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.phoneNumber))]),_c('span',{staticClass:"text-color-item"},[_vm._v(" "+_vm._s(_vm.$t("minha_conta.member"))+" "+_vm._s(_vm._f("moment")(_vm.membro,"DD/MM/YYYY HH:mm:ss"))+" ")])])]),_c('div',{class:{
                  'edicao-perfil': !_vm.isMobile,
                  'edicao-perfil-mobile': _vm.isMobile,
                }},[_c('div',{staticClass:"box-perfil"},[_c('h6',{staticClass:"textTitle"},[_c('customIcon',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$t("minha_conta.btn"))+" ")],1),_c('div',{class:{
                      'names-input': !_vm.isMobile,
                      'names-input-mobile': _vm.isMobile,
                    }},[_c('div',{staticClass:"item"},[_c('label',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.$t("minha_conta.name")))]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('minha_conta.txt')},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('div',{staticClass:"item"},[_c('label',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.$t("minha_conta.telephone")))]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"placeholder":_vm.$t('minha_conta.txt3')},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)])]),_c('button',{staticClass:"btn-atualizar-perfil",class:{ width100: _vm.isMobile },on:{"click":_vm.updateDataName}},[_vm._v(" "+_vm._s(_vm.$t("minha_conta.txt5"))+" ")])])]):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"coluna"}):_c('div',[(_vm.picProfile)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"foto-usuario",class:{ contains: _vm.picProfile },staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.$t('minha_conta.txt6'),"src":_vm.picProfile},on:{"click":function($event){_vm.show = true}}}):_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"foto-usuario",staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.$t('minha_conta.txt6')},on:{"click":function($event){_vm.show = true}}},[_c('span',[_vm._v(_vm._s(_vm.firstName ? _vm.firstName.substr(0, 1) : ''))])]),_c('div',{staticClass:"spaceDadosMobile mt-2"},[_c('h4',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.firstName))]),_c('p',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.email))]),_c('p',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.phoneNumber))])]),_c('div',{class:{
                  'edicao-perfil': !_vm.isMobile,
                  'edicao-perfil-mobile': _vm.isMobile,
                }},[_c('div',{staticClass:"box-perfil"},[_c('h6',{staticClass:"textTitle"},[_c('customIcon',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t("minha_conta.btn"))+" ")],1),_c('div',{class:{
                      'names-input': !_vm.isMobile,
                      'names-input-mobile': _vm.isMobile,
                    }},[_c('div',{staticClass:"item"},[_c('label',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.$t("minha_conta.name")))]),_c('b-form-input',{class:{ width100: _vm.isMobile },attrs:{"placeholder":_vm.$t('minha_conta.txt')},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('div',{staticClass:"item"},[_c('label',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.$t("minha_conta.last_name")))]),_c('b-form-input',{class:{ width100: _vm.isMobile },attrs:{"placeholder":_vm.$t('minha_conta.txt4')},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)]),_c('br'),_c('div',{class:{
                      'names-input': !_vm.isMobile,
                      'names-input-mobile': _vm.isMobile,
                    }},[_c('div',{staticClass:"item"},[_c('label',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.$t("minha_conta.telephone")))]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],class:{ width100: _vm.isMobile },attrs:{"placeholder":_vm.$t('minha_conta.txt3')},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)])]),_c('button',{staticClass:"btn-atualizar-perfil",class:{ width100: _vm.isMobile },on:{"click":_vm.updateDataName}},[_vm._v(" "+_vm._s(_vm.$t("minha_conta.txt5"))+" ")])])]),_c('div',{staticClass:"coluna"},[_c('div',{staticClass:"box-perfil"},[_c('h6',{staticClass:"textTitle"},[_c('customIcon',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t("minha_conta.change_password"))+" ")],1),_c('div',{class:{
                    'names-input': !_vm.isMobile,
                    'names-input-mobile': _vm.isMobile,
                  }},[_c('div',{staticClass:"item"},[_c('label',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.$t("minha_conta.label_password")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastPassword),expression:"lastPassword"}],class:{ width100: _vm.isMobile },attrs:{"type":"password","placeholder":_vm.$t('minha_conta.placeholder_password')},domProps:{"value":(_vm.lastPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.lastPassword=$event.target.value}}})]),_c('div',{staticClass:"item"},[_c('label',{staticClass:"text-color-item"},[_vm._v(_vm._s(_vm.$t("minha_conta.label_new_password")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],class:{ width100: _vm.isMobile },attrs:{"type":"password","placeholder":_vm.$t('minha_conta.placeholder_new_password')},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.newPassword=$event.target.value}}})])])]),_c('button',{staticClass:"btn-atualizar-perfil",class:{ width100: _vm.isMobile },on:{"click":_vm.updatePassword}},[_vm._v(" "+_vm._s(_vm.$t("minha_conta.update_password"))+" ")])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }