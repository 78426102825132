<template>
  <div data-anima="top" style="margin-left:60px;margin-top:20px;">
    <div style="display: flex; gap: 70px">
      <h1>{{ $t("minha_conta.title") }}</h1> 
      <div v-if="$route.path === '/minha-conta'"  style="top:-7px; width: 50%; position:relative;">
        <Locales />
      </div>
    </div>
    <!-- <div class="line"></div> -->
    <modal-upload></modal-upload>
    <div :class="{ containerx: !isMobile, '': isMobile }">
      <div
        :class="{
          'container-perfil': !isMobile,
          'container-perfil-mobile': isMobile,
        }"
      >
        <div class="side-left width100" :class="{ width100: isMobile }">
          <div
            class="animationOpacity2 width100"
            :class="{
              'edicao-perfil': !isMobile,
              'edicao-perfil-mobile': isMobile,
            }"
          >
            <div
              :class="{
                spaceConfigPainel: !isMobile,
                spaceConfigPainelMobile: isMobile,
              }"
            >
              <div v-if="!isMobile" class="coluna">
                <div class="flexProfile">
                  <div class="spacePic">
                    <img
                      v-if="picProfile"
                      v-b-tooltip.hover
                      :title="$t('minha_conta.txt6')"
                      class="foto-usuario"
                      :class="{ contains: picProfile }"
                      :src="picProfile"
                      @click="show = true"
                      style="cursor: pointer"
                    />
                    <div
                      v-else
                      class="foto-usuario"
                      v-b-tooltip.hover
                      :title="$t('minha_conta.txt6')"
                      style="cursor: pointer"
                      @click="show = true"
                    >
                      <span>{{ firstName ? firstName.substr(0, 1) : '' }}</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <my-upload
                        v-model="show"
                        field="value"
                        langType="pt-br"
                        :width="100"
                        :height="100"
                        :url="urlPost"
                        :params="params"
                        :headers="headers"
                        img-format="png"
                        @crop-success="cropSuccess"
                        @crop-upload-success="cropUploadSuccess"
                        @crop-upload-fail="cropUploadFail"
                      ></my-upload>
                      <img :src="imgDataUrl" />
                    </div>
                  </div>
                  <div class="spaceDados">
                    <h4 class="text-color-item">{{ firstName }}</h4>
                    <p class="text-color-item">{{ email }}</p>
                    <p class="text-color-item">{{ phoneNumber }}</p>
                    <span class="text-color-item">
                      {{ $t("minha_conta.member") }}
                      {{ membro | moment("DD/MM/YYYY HH:mm:ss") }}
                    </span>
                  </div>
                </div>
                <div
                  :class="{
                    'edicao-perfil': !isMobile,
                    'edicao-perfil-mobile': isMobile,
                  }"
                >
                  <div class="box-perfil">
                    <h6 class="textTitle">
                      <customIcon class="mr-2" />{{ $t("minha_conta.btn") }}
                    </h6>
                    <div
                      :class="{
                        'names-input': !isMobile,
                        'names-input-mobile': isMobile,
                      }"
                    >
                      <div class="item">
                        <label class="text-color-item">{{
                          $t("minha_conta.name")
                        }}</label>
                        <b-form-input
                          v-model="firstName"
                          :placeholder="$t('minha_conta.txt')"
                        ></b-form-input>
                      </div>
                      <div class="item">
                        <label class="text-color-item">{{
                          $t("minha_conta.telephone")
                        }}</label>
                        <b-form-input
                          v-model="phoneNumber"
                          v-mask="'(##) #####-####'"
                          :placeholder="$t('minha_conta.txt3')"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn-atualizar-perfil"
                    :class="{ width100: isMobile }"
                    @click="updateDataName"
                  >
                    {{ $t("minha_conta.txt5") }}
                  </button>
                </div>
              </div>
              <div v-if="!isMobile" class="coluna"></div>
              <div v-else>
                <img
                  v-if="picProfile"
                  v-b-tooltip.hover
                  :title="$t('minha_conta.txt6')"
                  class="foto-usuario"
                  :class="{ contains: picProfile }"
                  :src="picProfile"
                  style="cursor: pointer"
                  @click="show = true"
                />
                <div
                  v-else
                  v-b-tooltip.hover
                  :title="$t('minha_conta.txt6')"
                  class="foto-usuario"
                  style="cursor: pointer"
                  @click="show = true"
                >
                  <span>{{ firstName ? firstName.substr(0, 1) : '' }}</span>
                </div>
                <div class="spaceDadosMobile mt-2">
                  <h4 class="text-color-item">{{ firstName }}</h4>
                  <p class="text-color-item">{{ email }}</p>
                  <p class="text-color-item">{{ phoneNumber }}</p>
                </div>
                <div
                  :class="{
                    'edicao-perfil': !isMobile,
                    'edicao-perfil-mobile': isMobile,
                  }"
                >
                  <div class="box-perfil">
                    <h6 class="textTitle">
                      <customIcon class="mr-2" /> {{ $t("minha_conta.btn") }}
                    </h6>
                    <div
                      :class="{
                        'names-input': !isMobile,
                        'names-input-mobile': isMobile,
                      }"
                    >
                      <div class="item">
                        <label class="text-color-item">{{
                          $t("minha_conta.name")
                        }}</label>
                        <b-form-input
                          v-model="firstName"
                          :class="{ width100: isMobile }"
                          :placeholder="$t('minha_conta.txt')"
                        ></b-form-input>
                      </div>
                      <div class="item">
                        <label class="text-color-item">{{
                          $t("minha_conta.last_name")
                        }}</label>
                        <b-form-input
                          v-model="lastName"
                          :class="{ width100: isMobile }"
                          :placeholder="$t('minha_conta.txt4')"
                        ></b-form-input>
                      </div>
                    </div>
                    <br />
                    <div
                      :class="{
                        'names-input': !isMobile,
                        'names-input-mobile': isMobile,
                      }"
                    >
                      <div class="item">
                        <label class="text-color-item">{{
                          $t("minha_conta.telephone")
                        }}</label>
                        <b-form-input
                          v-model="phoneNumber"
                          v-mask="'(##) #####-####'"
                          :class="{ width100: isMobile }"
                          :placeholder="$t('minha_conta.txt3')"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn-atualizar-perfil"
                    :class="{ width100: isMobile }"
                    @click="updateDataName"
                  >
                    {{ $t("minha_conta.txt5") }}
                  </button>
                </div>
              </div>
              <div class="coluna">
                <div class="box-perfil">
                  <h6 class="textTitle">
                    <customIcon class="mr-2" />
                    {{ $t("minha_conta.change_password") }}
                  </h6>
                  <div
                    :class="{
                      'names-input': !isMobile,
                      'names-input-mobile': isMobile,
                    }"
                  >
                    <div class="item">
                      <label class="text-color-item">{{
                        $t("minha_conta.label_password")
                      }}</label>
                      <input
                        v-model="lastPassword"
                        type="password"
                        :placeholder="$t('minha_conta.placeholder_password')"
                        :class="{ width100: isMobile }"
                      />
                    </div>
                    <div class="item">
                      <label class="text-color-item">{{
                        $t("minha_conta.label_new_password")
                      }}</label>
                      <input
                        v-model="newPassword"
                        type="password"
                        :placeholder="
                          $t('minha_conta.placeholder_new_password')
                        "
                        :class="{ width100: isMobile }"
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="btn-atualizar-perfil"
                  :class="{ width100: isMobile }"
                  @click="updatePassword"
                >
                  {{ $t("minha_conta.update_password") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import myUpload from "vue-image-crop-upload";
import ModalUpload from "@/components/ModalUpload.vue";
import customIcon from "./icons/custom.vue";
import Cookies from "js-cookie";
import notify from "@/services/libs/notificacao";
export default {
  components: {   
    ModalUpload,   
    customIcon,
    "my-upload": myUpload,
    Locales: () => import("@/components/configs/Locales.vue"),  
  },
  data() {
    return {
      email: "",
      membro: "",
      client: {
        width: 0,
      },
      picProfile: null,
      NewPicProfileId: "",
      NewPicProfileUrl: "",
      logoPainel: null,
      NewLogoId: "",
      NewLogoUrl: "",
      color: "#00E4A0",
      swatches: [["#FF0C37"], ["#8b5aff"], ["#00E4A0"], ["#ffa51a"]],
      ratingHome: true,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      show: false,
      params: {},
      headers: {
        Authorization: Cookies.get("auth_greennCourse"),
      },
      imgDataUrl: "",
      urlPost: process.env.VUE_APP_API_HOST + "/member/meta/picture",
      newPassword: null,
      lastPassword: null,
    };
  },
  computed: {
    currentSite() { 
      return this.$store.getters.currentSite;
    },
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    updatePassword() {
      var data = {
        id: "change-password",
        password: this.lastPassword,
        new_password: this.newPassword,
      };

      serviceMember
        .postID(data)
        .then(() => {
          this.newPassword = null;
          this.lastPassword = null;
          notify("sucesso", this.$t("minha_conta.errors.update"));
        })
        .catch((err) => {
          console.error(err);
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") notify("erro", msg);
        });
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      this.picProfile = imgDataUrl;
    },
    cropUploadSuccess() {
      this.show = false;
      notify("sucesso", this.$t("minha_conta.errors.perfil"));
      this.$root.$emit("changedprofilepic");
    },
    cropUploadFail() {
      notify("erro", this.$t("minha_conta.errors.perfil_error"));
      this.show = false;
    },
    updateDataName() {
      var data = {
        id: "meta",
        name: this.firstName,
        cellphone: this.phoneNumber,
      };

      this.$root.$emit("loadOn");
      serviceMember
        .postID(data)
        .then(() => {
          notify("sucesso", this.$t("minha_conta.errors.atualizado"));
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));

      setTimeout(() => {
        this.getUserLogged();
      }, 2000);
    },
    getUserLogged() {
      this.$root.$emit("loadOn");
      serviceMember
        .read("/meta")
        .then((resp) => {
          this.firstName = resp.first_name;
          this.phoneNumber = resp.cellphone;
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    changedRating() {
      var status = this.ratingHome === true ? "on" : "off";
      let data = {
        id: "rating_home",
        value: status,
      };
      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then(() => this.getRating())
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    async getRating() {
      this.$root.$emit("loadOn");
      let resp = await this.$store.dispatch("fetchMetas", [
        "rating_home",
      ])
      if (resp.rating_home === null || resp.rating_home === "on") {
        this.ratingHome = true;
      } else {
        this.ratingHome = false;
      }
      this.$root.$emit("loadOff");
    },
    changedColor() {
      var data = {
        id: "main_color",
        value: this.color,
      };

      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then(() => {
          if (this.color !== null) {
            const d = document;
            d.documentElement.setAttribute("data-color", this.color);
            document.documentElement.style.setProperty(
              "--maincolor",
              this.color
            );
            document.documentElement.style.setProperty(
              "--maincolorn",
              this.$func.convertColor(this.color, 210)
            );
            document.documentElement.style.setProperty(
              "--maincolortrans",
              this.color + "0f"
            );
          }
          this.getColor();
          this.$root.$emit("changedmaincolor");
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    async getColor() {
      this.$root.$emit("loadOn");
      let resp = await this.$store.dispatch("fetchMetas", [
        "main_color"
      ]);
      this.color = resp.main_color;
      this.$root.$emit("loadOff");
    },
    updateLogoPainel() {
      var data = {
        id: "logo",
        value: this.NewLogoUrl,
      };

      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then(() => {
          this.getLogo();
          this.$root.$emit("changedlogo");
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    async getLogo() {
      this.$root.$emit("loadOn");
      let resp = await this.$store.dispatch("fetchMetas", [
        "logo"
      ]);
      this.logoPainel = resp.logo;
      this.$root.$emit("loadOff");
    },
    updateProfilePic() {
      var data = {
        id: "meta/picture",
        value: this.NewPicProfileUrl,
      };

      this.$root.$emit("loadOn");
      serviceMember
        .postID(data)
        .then(() => {
          this.getPicProfile();
          this.$root.$emit("changedprofilepic");
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    getPicProfile() {
      this.$root.$emit("loadOn");
      serviceMember
        .read("/meta")
        .then((resp) => {
          this.picProfile = resp.picture;
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    getCurrentSiteAndMember() {
      this.email = this.currentSite.currentMember.email;
      this.membro = this.currentSite.currentMember.created_at;
    },
    showModalUploadPic() {
      this.$root.$emit("picprofile");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadLogo() {
      this.$root.$emit("logopainel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
  },
  mounted() {
    this.getCurrentSiteAndMember();
    this.getUserLogged();
    this.getPicProfile();
    this.getLogo();
    this.getColor();
    this.getRating();
    this.$root.$on("newpicprofile", (data) => {
      this.NewPicProfileId = parseInt(data.id);
      this.NewPicProfileUrl = data.url;
      this.updateProfilePic();
    });
    this.$root.$on("newlogopainel", (data) => {
      this.NewLogoId = parseInt(data.id);
      this.NewLogoUrl = data.url;
      this.updateLogoPainel();
    });
  },
};
</script>

<style scoped lang="scss">
h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 15px;
  margin-left: 15px;
}

.text-color {
  color: var(--fontcolor) !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  padding-bottom: 25px;
  border-bottom: 0.5px solid #ededf0;
}

.text-color-item {
  color: var(--fontcolor) !important;
}

.hide {
  display: none;
}

.textTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--fontcolor);
  padding-bottom: 4px;
}

.spaceConfigPainel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 53px;
  width: 40em;
  // margin-top: 20px;
}

.spaceConfigPainelMobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 53px;
  margin-top: -20px;
}

.slidebarMobile {
  float: right;
  padding-top: 20px;
}
.container-grid {
  display: grid;
  grid-template-columns: 100px 1fr;
}
.containerx {
  width: 98%;
}

.flex {
  display: flex;
}

.flexProfile {
  display: flex;
  gap: 20px;
  // border-bottom: 0.5px solid #ededf0;
  width: 100%;
  // background: #a7a7a70d;
  // padding: 15px;
  border-radius: 15px;
}

.spaceColor {
  margin-top: 20px;
  margin-bottom: 20px;
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
    margin-bottom: 5px;
  }
}

.spaceConfigPainel {
  display: grid;
  gap: 22px;
  grid-template-columns: 1fr 1fr;
  // margin-top: 50px;
  width: 100%;
}

.subTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #81858e;
}

.coluna {
  width: 100%;
}

.btnChangePic {
  height: 45px;
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  width: 250px;
  font-weight: 600;
  font-size: 14px;
  color: #00e4a0;
  background: rgba(0, 228, 160, 0.05);
  border: 2px dashed #00e4a0;
  padding: 0px 15px;
  margin-top: 40px;
  margin-left: 30px;
}

.spaceDados {
  p {
    margin-top: 23px;
    margin-bottom: 23px;
  }
}

.logoPainel {
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.spacePic {
  align-items: center;
  display: flex;
}

.picProfile {
  width: 100px;
  height: 100px;
  // background: rgba(129, 133, 142, 0.2);
  // border: 1px solid #81858E;
  box-sizing: border-box;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 13px;
  img {
    width: 100%;
  }
}

.picProfile.picMobile {
  margin-top: 25px;
}

.width100 {
  width: 100% !important;
}

/* === */
.container-perfil {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 15px;
}

.side-left h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 1px 0;
}
.side-left p {
  font-size: 14px;
  margin: 1px 0;
  font-weight: 500;
}
.side-left span {
  color: #81858e;
  font-size: 14px;
  font-weight: 500;
}

.edicao-perfil-mobile {
  margin: 40px 0;
  width: 100%;
}
.box-perfil {
  margin-top: 20px;
  padding: 20px 0;
  width: 100%;
}
.names-input {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.names-input-mobile {
  display: block;
  align-items: flex-start;
}

.names-input .item {
  display: block;
}
.names-input .item + .item {
  margin-left: 30px;
}
.names-input .item label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.names-input .item input {
  border: none;
  border: solid 1px var(--bordercolor);
  border-radius: 5px;
  height: 55px;
  padding: 20px;
  font-size: 14px;
  transition: 0.3s;
  width: 235px;
}
.names-input .item input:hover,
.names-input .item input:focus {
  border-color: var(--maincolor);
  background: var(--maincolortrans);
  box-shadow: 0px 4px 10px var(--maincolortrans);
}
.names-input .item input:focus {
  font-weight: 600;
}

.names-input-mobile .item {
  display: block;
}
.names-input-mobile .item + .item {
  margin-top: 20px;
}
.names-input-mobile .item label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.names-input-mobile .item input {
  border: none;
  border: solid 1px var(--bordercolor);
  border-radius: 5px;
  height: 55px;
  padding: 20px;
  font-size: 14px;
  transition: 0.3s;
  width: 235px;
}
.names-input-mobile .item input:hover,
.names-input-mobile .item input:focus {
  border-color: var(--maincolor);
  background: var(--maincolortrans);
  box-shadow: 0px 4px 10px var(--maincolortrans);
}
.names-input-mobile .item input:focus {
  font-weight: 600;
}

.btn-atualizar-perfil {
  border: none;
  background: var(--maincolor);
  color: #fff;
  outline: none;
  font-weight: 600;
  height: 55px;
  padding: 0 42px;
  border-radius: 5px;
}

.side-right {
  width: 100%;
}
.side-right img {
  margin-left: 120px;
  width: 300px;
}

.foto-usuario {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: var(--maincolor);
  object-fit: contain;
  display: flex;
  justify-content: center;
}

.foto-usuario span {
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}

.foto-usuario.contains {
  background: none;
}
</style>
